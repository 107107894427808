var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',{staticClass:"admin-wbs"},[(_vm.isPageLoading)?_c('div',{staticClass:"admin-wbs--loading"},[_c('v-progress-circular',{attrs:{"width":5,"color":"grey","indeterminate":"","size":30}}),_c('div',{staticClass:"grey--text",style:({
          'margin-top': '12px',
          'font-size': '14px'
        })},[_vm._v(" Loading ")])],1):_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":"","pt-0":"","pb-0":""}},[_c('admin-approver-card-location',{attrs:{"title":"Finance FP&A Approver","color":_vm.section_color,"approvers":_vm.financeApprovers,"loading":_vm.isFinanceApproverLoading},on:{"add":_vm.startAddFinanceApprover,"edit":_vm.startEditFinanceApprover,"remove":_vm.startRemoveFinanceApprover}}),_c('admin-approver-card-location',{attrs:{"title":"Legal Approver","color":_vm.section_color,"approvers":_vm.legalApprovers,"loading":_vm.isLegalApproverLoading},on:{"add":_vm.startAddLegalApprover,"edit":_vm.startEditLegalApprover,"remove":_vm.startRemoveLegalApprover}})],1),_c('v-flex',{attrs:{"xs12":"","lg6":"","pt-0":"","pb-0":""}},[_c('admin-approver-card',{attrs:{"title":"Finance Leader Approver","color":_vm.section_color,"approvers":_vm.financeLeaderApprovers,"loading":_vm.isFinanceLeaderApproverLoading},on:{"add":_vm.startAddFinanceLeaderApprover,"edit":_vm.startEditFinanceLeaderApprover,"remove":_vm.startRemoveFinanceLeaderApprover}}),_c('admin-approver-card',{attrs:{"title":"CPG Leader Approver","color":_vm.section_color,"approvers":_vm.cpgLeaderApprovers,"loading":_vm.isCpgLeaderApproverLoading},on:{"add":_vm.startAddCpgLeaderApprover,"edit":_vm.startEditCpgLeaderApprover,"remove":_vm.startRemoveCpgLeaderApprover}}),_c('admin-approver-card',{attrs:{"title":"CI Leader Approver","color":_vm.section_color,"approvers":_vm.ciLeaderApprovers,"loading":_vm.isCiLeaderApproverLoading},on:{"add":_vm.startAddCiLeaderApprover,"edit":_vm.startEditCiLeaderApprover,"remove":_vm.startRemoveCiLeaderApprover}})],1),_c('confirm-dialog',{attrs:{"card-style":{
          width: '450px'
        },"title":'Remove -- ' + _vm.edit.tableName,"btn-color":_vm.section_color},on:{"click-yes":_vm.doRemove},model:{value:(_vm.isRemovingApprover),callback:function ($$v) {_vm.isRemovingApprover=$$v},expression:"isRemovingApprover"}},[_c('div',{staticStyle:{"height":"60px"}},[_vm._v(" Do you confirm to remove "),_c('b',[_vm._v(_vm._s(_vm.edit.target.name))]),(_vm.edit.industry)?[_vm._v(" of "),_c('b',[_vm._v(_vm._s(_vm.edit.industry))])]:_vm._e(),_vm._v(" from "),_c('b',[_vm._v(_vm._s(_vm.edit.tableName))]),_vm._v("? ")],2)]),_c('admin-edit-dialog',{attrs:{"card-style":{
          width: '600px'
        },"title":_vm.edit.typeText + ' -- ' + _vm.edit.tableName,"btn-color":_vm.section_color,"role":_vm.edit.role,"roles":_vm.edit.roles,"target":_vm.edit.target,"notified":_vm.edit.notified,"location":_vm.edit.location,"location-textfield":_vm.isLocationTextfieldShown},on:{"click-done":_vm.saveEdit},model:{value:(_vm.isEditingApprover),callback:function ($$v) {_vm.isEditingApprover=$$v},expression:"isEditingApprover"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }