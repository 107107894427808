<template>
  <v-dialog
    :value="value"
    persistent
    width="fit-content"
  >
    <v-card :class="cardClass" :style="cardStyle">
      <v-list :class="titleClass" :style="titleStyle">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="font-size: 20px;">{{ title }}</v-list-item-title>
          </v-list-item-content>

          <v-spacer></v-spacer>

          <v-list-item-action>
            <v-btn text icon @click="clickClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div class="admin-edit-dialog-content mt-2">
        <v-list style="padding: 0 15px;display:flex">
          <div class="account-autocomplete">
            <account-autocomplete
              v-model="my_target"
              label="Email *"
              :hide-details="false"
              ></account-autocomplete>
          </div>
          <div class="mt-1 ml-3" style="width:170px;">
            <v-select
              v-if="isApprover"
              style="margin-top:0px"
              class="account-autocomplete-hori"
              label="Access *"
              :items="['Approver']"
              disabled
              v-model="my_role">
            </v-select>
            <v-select
              v-else
              style="margin-top:0px"
              class="account-autocomplete-hori"
              label="Access *"
              :items="my_roles"
              placeholder="Please Select Access"
              v-model="my_role">
            </v-select>
          </div>
        </v-list>
        <v-list style="margin-right:170px;padding: 0 15px;display:flex">
          <v-select
            v-if="industrySelection"
            v-model="my_industry"
            label="Industry *"
            placeholder="Please Select Industry"
            :disabled="isApprover"
            :items="industriesInSelect"
            ></v-select>
        </v-list>
        <v-list style="margin-right:170px;padding: 0 15px;display:flex">
          <v-text-field
            v-if="locationTextfield"
            v-model="my_location"
            label="Location"
            disabled
            ></v-text-field>
        </v-list>
        <v-list style="margin-top:-9px;padding: 0 15px;">
          <v-checkbox
            v-if="isApprover"
            :color="section_color"
            input-value="true"
            disabled
            label="Receive Request Notifications"
            ></v-checkbox>
          <v-checkbox
            v-else
            :color="section_color"
            v-model="my_notified"
            label="Receive Request Notifications"
            ></v-checkbox>
        </v-list>
      </div>

      <div style="overflow: auto;">
        <div style="padding: 8px 18px;text-align: right;">
          <v-btn dark :color="btnColor" 
            @click="clickDone">
            {{ doneText }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { section_color } from '@/config'
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

import AccountAutocomplete from '@/components/vuetify-extension/account-autocomplete'


export default {
  components: {
    AccountAutocomplete
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    target: {
      type: Object,
      default: () => ({})
    },
    role: {
      type: String,
      default: null
    },
    roles: {
      type: Array,
      default: () => ([])
    },
    notified: {
      type: String,
      default: null
    },
    industry: {
      type: String,
      default: null
    },
    industrySelection: {
      type: Boolean,
      default: false
    },
    location: {
      type: String,
      default: null
    },
    locationTextfield: {
      type: Boolean,
      default: false
    },
    cardClass: {
      type: [Object, Array],
      default: () => ({})
    },
    cardStyle: {
      type: Object,
      default: () => ({})
    },
    titleClass: {
      type: [Object, Array],
      default: () => (['white'])
    },
    titleStyle: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'warning darken-1'
    },
    btnColor: {
      type: String,
      default: 'primary'
    },
    comments: {
      type: String,
      default: 'Please confirm'
    },
    showNo: {
      type: Boolean,
      default: true
    },
    noFlat: {
      type: Boolean,
      default: true
    },
    noColor: {
      type: String,
      default: 'info'
    },
    showYes: {
      type: Boolean,
      default: true
    },
    doneText: {
      type: String,
      default: 'Done'
    },
    yesColor: {
      type: String,
      default: 'info'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      my_target: {
        email: null,
        name: null,
        icon: null
      },
      my_role: null,
      my_roles: [],
      my_industry: null,
      my_location: null,
      my_notified: null,
      industries: []
    }
  },
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),

    // props
    section_color () {
      return section_color['wbs']
    },

    // status
    isApprover () {
      return (this.my_role == 'Approver')
    },

    // menu/array
    industriesInSelect () {
      if (this.isApprover) {
        return [this.my_industry, ...this.industries]
      } else {
        return this.industries
      }
    }
  },
  methods: {
    // actions
    clickClose () {
      this.$emit('change', false)
    },
    clickDone (e) {
      if (!this.checkDone()) return

      this.$emit('click-done', {
        target: this.my_target,
        role: this.my_role,
        industry: this.my_industry,
        location: this.my_location,
        notified: this.my_notified
      })
    },

    // functions
    checkDone () {
      // any empty
      if (
        !this.my_target.email || 
        !(this.industrySelection ? this.my_industry : true) || 
        !this.my_role
      ) {
        this.$eventBus.$emit('snackbar', { type: 'warning', text: 'Please enter all information required (*).', time: 4 })
        return false
      }

      // no change
      if (
        (this.my_target.email == this.target.email) &&
        (this.industrySelection ? (this.my_industry == this.industry) : true) &&
        (this.my_role == this.role) &&
        (this.my_notified == this.notified)
      ) {
        this.$eventBus.$emit('snackbar', { type: 'warning', text: 'Change not found.', time: 4 })
        return false
      }

      return true
    },
    async getIndustry () {
      try {
        const res = await xPost(
          'normal',
          'industry',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          const data = res.data.data || []
          this.industries = data.map(el => el.Industry)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get industries]')
        // console.log(err)
      }
    }
  },
  watch: {
    async value (val) {
      if (val) {
        if (this.target instanceof Object) {
          let target = {
            email: this.target.email || null,
            name: this.target.name || null,
            icon: this.target.icon || null
          }
          this.my_target = target
        } else {
          let target = {
            email: null,
            name: null,
            icon: null
          }
          this.my_target = target
        }
        this.my_roles = this.roles
        this.my_role = this.role
        this.my_industry = this.industry
        this.my_location = this.location
        this.my_notified = this.notified
        const get_industry = await this.getIndustry()
      }
    },
  }
}
</script>

<style lang="sass">
  .admin-edit-dialog-content
    .account-autocomplete
      flex: 1
      .v-autocomplete
        .v-select__slot
          padding: 9px 0

    .account-autocomplete-hori
      .v-select__slot
        padding: 7px 0
</style>
