<template>
  <div class="admin--approver-card--location">
    <material-app-card
      :color="color"
      icon="mdi-text-box"
      :title="title"
    >
      <template v-slot:extra>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn small text icon dark :color="color"
              @click="add"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add</span>
        </v-tooltip>
      </template>
      <template v-slot>
        <v-layout row wrap class="v-card-x-layout">
          <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell"
            >
            <div v-if="loading" style="display: flex;align-items: center;justify-content: center;height: 110px;">
              <v-progress-circular
                :width="4"
                color="grey"
                indeterminate
                :size="24"
              ></v-progress-circular>
              <div
                class="grey--text text--lighten-1"
                :style="{
                  'margin-left': '9px'
                }"
              >
                {{ loading === true ? 'Loading' : loading }}
              </div>
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="approvers"
              item-key="key"
              :items-per-page="-1"
              hide-default-footer>
              <template v-slot:item="props">
                <tr>
                  <td style="width:120px;">
                    {{ props.item.role }}
                  </td>
                  <td style="width:120px;">
                    {{ props.item.location }}
                  </td>
                  <td>
                    <account-profile
                      :email="props.item.user.email"
                      :name="props.item.user.name"
                      :icon="props.item.user.icon"
                      :tag-icon-size="32"
                      :tag-color="themeColor.default"
                      :gap-of-tag-icon-and-name="12"
                    >
                    </account-profile>
                  </td>
                  <td style="text-align:center;width:120px;min-width:120px;">
                    <v-btn small text icon dark :color="color"
                      @click="edit(props.item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small text icon dark :color="color"
                      :disabled="!props.item.deletable"
                      @click="remove(props.item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>              
                </tr>
              </template>
              <template v-slot:no-data>
                <div style="width:100%;text-align:center">No Data</div>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </template>
    </material-app-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AccountProfile from '@/components/vuetify-extension/account-profile'

export default {
  components: {
    AccountProfile
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    },
    approvers: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Access',
          sortable: false,
          width: '120px'
        },
        {
          text: 'Location',
          sortable: false,
          width: '120px'
        },
        {
          text: 'Name',
          sortable: false
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'center',
          width: '120px'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', {
      themeColor: state => state.color
    })
  },
  methods: {
    add() {
      this.$emit('add')
    },
    edit(item) {
      this.$emit('edit', item)
    },
    remove(item) {
      this.$emit('remove', item)
    }
  }
}
</script>

<style lang="sass">
  .admin--approver-card--location
    .admin--approver-card-loading
      display: flex
      align-items: center
      width: 100%

    .v-card-x-layout
      .v-card-x-title
        font-size: 14px
        padding-left: 9px !important
        padding-right: 9px !important
        padding-bottom: 6px !important

      .v-card-x-cell
        text-align: left

        .v-card-x-cell-title
          font-size: 14px
          color: rgba(0,0,0,1)
          transform: translateX(-12.5%) scale(.75)
          -webkit-transform: translateX(-12.5%) scale(.75)

        .v-card-x-cell-title-absolute
          position: absolute
</style>
